import config from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state: {
    tipos_cheque: [],
    formatos_cheque: []
  },
  mutations: {
    set_tipos (state, payload) {
      state.tipos_cheque = payload
    },
    set_formatos (state, payload) {
      state.formatos_cheque = payload
    }
  },
  actions: {
    async get_formatos ({ state, commit }) {
      try {
        if (state.formatos_cheque.length == 0) {
          const res = await fetch(`${config.BASE_URL}/cheques/formatos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_formatos', data.data)
          } else {
            commit('set_formatos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_formatos', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_tipos ({ state, commit }) {
      try {
        if (state.tipos_cheque.length == 0) {
          const res = await fetch(`${config.BASE_URL}/cheques/tipos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tipos', data.data)
          } else {
            commit('set_tipos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tipos', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    }
  }
}