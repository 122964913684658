import config from '../../config'
import store from '../store'
import { order_list_by } from '../util/utils'

export default {
  namespaced: true,
  state: {
    vendedor: null,
    favorito: null,
    cargaInc: false,
    nombreUs: '',
    inc_estados: [],
    solicitantes: []
  },
  mutations: {
    set_estados (state, payload) {
      order_list_by(payload, 'nombre')
      state.inc_estados = payload
    },
    set_solicitantes (state, payload) {
      order_list_by(payload, 'nombre')
      state.solicitantes = payload
    },
    set_vendedor (state, payload) {
      state.vendedor = payload.vendedor
      state.favorito = payload.favorito
      state.cargaInc = payload.cargaInc
      state.nombreUs = payload.nombre
    }
  },
  actions: {
    async finalizar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/incidentes/finalizar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async comentario ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/incidentes/comentario`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            // envia la notificacion push
            store.dispatch('enviar_push')
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_comentarios ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/incidentes/comentarios?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_archivos ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/incidentes/archivos?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async nuevo ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/incidentes/nuevo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_sucursales ({}, empresa) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/incidentes/sucursales?empresa=${empresa}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_incidentes ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/incidentes/search?id=${filtro.numero}&estado=${filtro.estado ? filtro.estado : ''}&asunto=${filtro.asunto}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async info_user ({ state, commit }) {
      try {
        if (state.vendedor === null) {
          const res = await fetch(`${config.BASE_URL}/incidentes/infoUser`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_vendedor', data.data)
          } else {
            commit('set_vendedor', {
              vendedor: null,
              favorito: null,
              cargaInc: false,
              nombre: store.state.username
            })
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_vendedor', {
          vendedor: null,
          favorito: null,
          cargaInc: false,
          nombre: store.state.username
        })
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_solicitantes ({ state, commit }) {
      try {
        if (state.solicitantes.length == 0) {
          const res = await fetch(`${config.BASE_URL}/incidentes/solicitantes`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_solicitantes', data.data)
          } else {
            commit('set_solicitantes', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_solicitantes', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_estados ({ state, commit }) {
      try {
        if (state.inc_estados.length == 0) {
          const res = await fetch(`${config.BASE_URL}/incidentes/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_estados', data.data)
          } else {
            commit('set_estados', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_estados', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    }
  }
}