import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    tipos_gastos: []
  },
  mutations: {
    set_tipos_gastos (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipos_gastos = payload
    }
  },
  actions: {
    async get_gastos_empresas ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/getGastosEmpresas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_tipos_gastos ({ state, commit }) {
      if (state.tipos_gastos.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/controlCaja/tiposGastos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_tipos_gastos', data.data)
          } else {
            commit('set_tipos_gastos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_tipos_gastos', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    }
  }
}