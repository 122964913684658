import config from "../../config";
import store from "../store";

export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    //Iniciar Formulario
    async iniciarFormulario({}){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminRada/initFormLiq`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al iniciar el formulario de Liquidaciones: " + error.message
          })
        }
      })
    },
    //Obtener Excel
    async obtenerExcel({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminRada/getExcelLiq?periodo=${payload}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al generar el Excel: " + error.message
          })
        }
      })
    },
    //Borrar Periodo
    async borrarPeriodo({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminRada/deleteRadaLiq`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
             body: JSON.stringify(payload)
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al borrar la Liquidación: " + error.message
          })
        }
      })
    },
    //Nueva Liquidacion 
    async nuevaLiquidaciones({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminRada/newLiqRada`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
             body: JSON.stringify(payload)
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al crear la Liquidación: " + error.message
          })
        }
      })
    },
    //BuscarDetalles
    async buscarDetalleLiq({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminRada/getDetallesLiq?periodo=${payload.periodo}&concepto_id=${payload.concepto_id}&reserva_id=${payload.reserva_id}&nro_contrato=${payload.nro_contrato}&origen=${payload.origen}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al obtener los detalles de liquidación: " + error.message
          })
        }
      })
    },
    //newLiqRadaMasivo, POST, periodos: []  (no puede ser vacio): { periodo: 'dd/mm/yyyy' }
    //Elimina una fila del datable Detalles
    async eliminarUnDetalleLiq({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminRada/deleteDetLiq`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
             body: JSON.stringify(payload)
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al eliminar detalle de Liquidación: " + error.message
          })
        }
      })
    },
    //Modificar detalle de periodo de liquidacion
    async modificarLiquidacion({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminRada/modifyDetLiq`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
             body: JSON.stringify(payload)
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al modificar el detalle la Liquidación: " + error.message
          })
        }
      })
    },
  }
}
