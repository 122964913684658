import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    tipos_movimiento: [],
    all_cuentas: [],
    bancos: []
  },
  mutations: {
    set_tipos_movimiento (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipos_movimiento = payload
    },
    set_all_cuentas (state, payload) {
      state.all_cuentas = payload
    },
    set_bancos (state, payload) {
      state.bancos = payload
      order_list_by(state.bancos, 'nombre')
    }
  },
  actions: {
    async get_all_cuentas ({ commit }) {
      try {
        const res = await fetch(`${config.BASE_URL}/allCuentas`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.long_token
          }
        })
  
        const data = await res.json()
  
        if (data.exito == 1) {
          commit('set_all_cuentas', data.data)
        } else {
          commit('set_all_cuentas', [])
          store.dispatch('show_snackbar', {
            text: data.message,
            color: 'error'
          })
        }
  
      } catch (error) {
        commit('set_all_cuentas', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_tipos_movimiento ({ state, commit }) {
      if (state.tipos_movimiento.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/tiposMovimiento`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_tipos_movimiento', data.data)
          } else {
            commit('set_tipos_movimiento', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_tipos_movimiento', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    // obtiene los bancos habilitados y los guarda en el state para utilizarlos en otros formularios
    async get_bancos_habilitados ({ commit, dispatch }, empresa) {
      await dispatch('get_bancos', { inhabilitado: 0, empresa: empresa })
        .then((res) => {
          commit('set_bancos', res.data)
        })
        .catch((error) => {
          commit('set_bancos', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
    },
    async get_bancos ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bancos/getBancos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_bancos_pais({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let bancosPeticion = await fetch(`${config.BASE_URL}/bancos/getBancosPais`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let bancos = await bancosPeticion.json()
          return resolve(bancos)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_bancos_pais: ' + error.message
          })
        }
      })
    }
  }
}