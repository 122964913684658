import moment from 'moment'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

/** TODOS LOS TIPO DE ARCHIVO QUE ACEPTA ULTRON VAN EN ESTE ARRAY
 *  (los mime deben ser todos distintos, en el caso que exista un mismo
 *  mime con dos extensiones distintas (como el caso de jpg y jpeg),
 *  tiraría error)
 */
const tipos_archivos = [
  // pdf
  { extension: '.pdf', mime: 'application/pdf', icono: 'far fa-file-pdf', color: 'red darken-4', tipo: 'PDF' },
  // imagenes
  { extension: '.png', mime: 'image/png', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jpg', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jpe', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jif', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jfi', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jpeg', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jfif', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.webp', mime: 'image/webp', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  // documentos de texto
  { extension: '.txt', mime: 'text/plain', icono: 'far fa-file-alt', color: '', tipo: 'TXT' },
  { extension: '.doc', mime: 'application/msword', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOC' },
  { extension: '.docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOC' },
  { extension: '.odt', mime: 'application/vnd.oasis.opendocument.text', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOC' },
  // hojas de cálculo
  { extension: '.csv', mime: 'text/csv', icono: 'fas fa-file-csv', color: 'green darken-4', tipo: 'CSV' },
  { extension: '.xls', mime: 'application/vnd.ms-excel', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'XLS' },
  { extension: '.xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'XLS' },
  { extension: '.ods', mime: 'application/vnd.oasis.opendocument.spreadsheet', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'XLS' },
  // video
  { extension: '.mp4', mime: 'video/mp4', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
  { extension: '.avi', mime: 'video/x-msvideo', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
  { extension: '.mpg', mime: 'video/mpeg', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
  { extension: '.webm', mime: 'video/webm', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
]

// mascara para el formato de numeros enteros
const number_mask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '.',
  includeThousandsSeparator: true,
  allowNegative: false,
})

// regex para validar email
const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// el formato de la fecha debe ser DD MM YYYY (en el orden que se desee) separados por - o /
// si no se especifica el formato, se convierte a DD/MM/YYYY
function format_date (date, format) {
  if (!date) return ''
  return moment(date).format(format ? format : 'DD/MM/YYYY')
}

// money debe ser de tipo numérico o flotante
function format_money (money) {
  if (!money && money != 0) return ''
  return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(money)
}

export function ArrayBufferToBase64 (buffer) {
  let binary = ''
  let bytes = new Uint8Array(buffer)
  let len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

// convierte un archivo en base64
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

// ordena una lista de onjetos segun el atributo (key) especificado
// se pude elegir entre desc y asc (con orden), si no se indica alguno por defecto ordena desc
function order_list_by (list, key, orden) {
  let desc = -1
  let asc = 1
  if (orden == 'asc') {
    desc = 1
    asc = -1
  }
  list.sort(function(a, b) {
    if(a[key] < b[key]) { return desc }
    if(a[key] > b[key]) { return asc }
    return 0
  })
}

// convierte una fecha en formato DD/MM/YYYY a YYYY-MM-DD
function parseDate (date) {
  if (!date) return null
  const [day, month, year] = date.split('/')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

// devuelve un objeto con el ancho y alto de una imagen en base64
async function getDims (base64) {
  return await new Promise((resolve) => {
    let img = new Image()
    img.src = base64
    img.onload = () => {
      resolve({
        ancho: img.width,
        alto: img.height
      })
    }
  })
}

// como nvl pero devuelve el valor en string
export function str (value, newValue) {
  if (value == null) return newValue
  else return value.toString()
}

export function cadenaVacia(cadena){
  if (cadena == null || cadena == undefined || cadena.toString().length == 0) return true
  else return false
}

/**
 * Nota: esta funcion divide un array en sub arrays de longitud n
 */
 export function divideArray (array, longitud) {
  let result = []
  while (array.length > 0) {
    result.push(array.splice(0, longitud))
  }
  return result
}

export function convertDecimals(numero){
  return parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
}

export function roundNumber(num, scale) {
  if(!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale)  + "e-" + scale);
  } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
          sig = "+";
      }
      return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}

// formatea un valor con comillas simples si el valor es null, undefined, '' o 0
// se utiliza para dar formato a filtros de tipo query que son de un select p.e.
export function formatQuery (valor) {
  return valor ? valor : ''
}

// devuelve la extension de un nombre de archivo
export function getExtensionFileName (file_name) {
  return file_name.substring(file_name.lastIndexOf('.'), file_name.size)
}

// redondea un numero con el tu fixed, si no se le pasa los decimales redondea a 2 por defecto
export function roundNumberTF (value, decimals) {
  if (!value) return 0
  else return parseFloat(value.toFixed(decimals ? decimals : 2))
}

export function format_money_round (money, precision) {
  if (!money && money != 0) return ''
  return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', maximumFractionDigits: precision? precision : 0 }).format(money)
}

export function objetoNoVacio(obj){
  if (obj == null || obj == undefined || typeof obj != 'object' || Array.isArray(obj) || Object.keys(obj).length == 0) return false
  else return true
}

export {
  tipos_archivos,
  number_mask,
  REGEX_EMAIL,
  format_date,
  format_money,
  getBase64,
  order_list_by,
  parseDate,
  getDims
}