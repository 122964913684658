import config from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async get_financiaciones_habilitadas({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let fpPeticion = await fetch(`${config.BASE_URL}/financiaciones/getFinanciacionesHabilitadas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let fps = await fpPeticion.json()
          fps.msj = fps.message
          return resolve(fps)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_financiaciones_habilitadas: ' + error.message,
            financiaciones: null
          })
        }
      })
    },
    async init_formas_pago({}){
      return await new Promise(async (resolve, reject) => {
        try{
          //${config.BASE_URL}
          let initPeticion = await fetch(`${config.BASE_URL}/financiaciones/initFormasPago`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo init_formas_pago: ' + error.message,
            financiaciones: null
          })
        }
      })
    },
    async get_formas_pago_venta({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let detsPeticion = await fetch(`${config.BASE_URL}/financiaciones/getFormasPagoVenta?nro_pedido=${datos.nro_pedido}&tipo_venta=${datos.tipo_venta}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let dets = await detsPeticion.json()
          dets.msj = dets.message
          return resolve(dets)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_formas_pago_venta: ' + error.message,
            financiaciones: null
          })
        }
      })
    }
  }
}