import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {
    locales_user: []
  },
  mutations: {
    set_locales (state, payload) {
      state.locales_user = payload
    }
  },
  actions: {
    async get_locales_user ({ state, commit }) {
      try {
        if (state.locales_user.length == 0) {
          const res = await fetch(`${config.BASE_URL}/vendedores/localesUser`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_locales', data.data)
          } else {
            commit('set_locales', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_locales', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    }
  }
}