import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import config from '../../config'
import { VueEasyJwt } from "vue-easy-jwt"

const jwt = new VueEasyJwt()
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/notificaciones',
    name: 'Notificaciones',
    component: () => import(/* webpackChunkName: "notificaciones" */ '../views/Notificaciones.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/descargarArchivo',
    name: 'Descargar Archivo',
    component: () => import(/* webpackChunkName: "descargar-archivo" */ '../views/DescargarArchivo.vue'),
    meta: { requireAuth: true }
  },
  // ----------------------------------
  //           COBRO ONLINE
  // ----------------------------------
  {
    path: '/cobro-online',
    name: 'Cobro Online',
    component: () => import(/* webpackChunkName: "cobro-online" */ '../views/cobro-online/Index.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/nuevo-cobro',
    name: 'Nuevo Cobro Online',
    component: () => import(/* webpackChunkName: "cobro-online" */ '../views/cobro-online/UnCobro.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  {
    path: '/reprocesar-cobro/:tipo_pedido/:id',
    name: 'Reprocesar Cobro',
    component: () => import(/* webpackChunkName: "cobro-online" */ '../views/cobro-online/UnCobro.vue'),
    meta: { 
      requireAuth: true, 
      rutaProtegida: true 
    }
  },
  // ----------------------------------
  //           RRHH
  // ----------------------------------
  {
    path: '/evaluaciones',
    name: 'Evaluación Desempeño',
    component: () => import(/* webpackChunkName: "rrhh" */ '../views/rrhh/Evaluaciones.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/evaluacion/:id',
    name: 'Evaluación',
    component: () => import(/* webpackChunkName: "rrhh" */ '../views/rrhh/Evaluacion.vue'),
    meta: { requireAuth: true }
  },
  // ----------------------------------
  //           INCIDENTES
  // ----------------------------------
  {
    path: '/incidentes/:id?',
    name: 'Carga de Incidentes',
    component: () => import(/* webpackChunkName: "incidentes" */ '../views/incidentes/Incidentes.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           CAJA
  // ----------------------------------
  {
    path: '/movimientos-caja',
    name: 'Operaciones de Caja',
    component: () => import(/* webpackChunkName: "caja" */ '../views/caja/Caja.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           RESERVAS
  // ----------------------------------
  {
    path: '/index/reservas',
    name: 'Reservas',
    component: () => import(/* webpackChunkName: "reservas" */ '../views/reservas/IndexReservas.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/reserva/:id',
    name: 'Detalle de Reserva',
    component: () => import(/* webpackChunkName: "ver-editar-reserva" */ '../views/reservas/UnaReserva.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/reserva/prefactura/:id',
    name: 'Prefactura',
    component: () => import(/* webpackChunkName: "prefactura-reserva" */ '../views/reservas/UnaReserva.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
    // ----------------------------------
  //           LIQUIDACIONES
  // ----------------------------------
  {
    path: '/index/liquidaciones',
    name: 'Liquidaciones',
    component: () => import(/* webpackChunkName: "liquidaciones-index" */ '../views/liquidaciones/IndexLiquidaciones.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },  
  // ----------------------------------
  //           OTRAS
  // ----------------------------------
  {
    path: '/soporte',
    name: 'Soporte',
    component: () => import(/* webpackChunkName: "soporte" */ '../views/Soporte.vue'),
    meta: { requireAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (is_auth()) {
      store.dispatch('get_notificaciones_noleidas')
      if (to.matched.some(record => record.meta.rutaProtegida)) {
        try {
          store.state.loading = true

          const res = await fetch(`${config.BASE_URL}/user/rutaValida`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Base ' + JSON.parse(localStorage.getItem('token'))
            },
            body: JSON.stringify({
              path: to.path,
              redirect: true,
            })
          })
  
          const data = await res.json()
  
          if (data.exito === 1) {
            next()
          } else {
            next({ path: '/' })
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }

          store.state.loading = false
        } catch (error) {
          next({ path: '/' })
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      } else {
        next()
      }
    } else {
      next({ path: '/login' })
      if (JSON.parse(localStorage.getItem('token'))) {
        store.dispatch('logout', false)
        store.dispatch('show_snackbar', {
          text: 'La sesión actual expiró.',
          color: 'error'
        })
      }
    }
  } else {
    next()
  }
  window.scrollTo(0, 0)
})

function is_auth () {
  const token = JSON.parse(localStorage.getItem('token'))
  return !jwt.isExpired(token)
}

export default router