import Vue from 'vue'
import Vuex from 'vuex'
import config from '../../config'
import { Encrypt, Decrypt } from "../util/aes"

// librerias
import { VueEasyJwt } from "vue-easy-jwt"

// modulos
import vendedores from '../modules/vendedores'
import caja from '../modules/caja'
import financiaciones from '../modules/auditoria/financiaciones'
import cobros from '../modules/cobros'
import bancos from '../modules/generales/bancos'
import controlCaja from '../modules/auditoria/controlCaja'
import laposIntegrado from '../modules/laposIntegrado'
import cheques from '../modules/auditoria/cheques'
import evaluaciones from '../modules/rrhh/evaluaciones'
import incidentes from '../modules/incidentes'
import archivos from '../modules/generales/archivos'
import afip from '../modules/afip'
import reservas from '../modules/reservas/reservas'
import liquidaciones from '../modules/liquidaciones'

const jwt = new VueEasyJwt()
Vue.use(Vuex)

/**
 *   Desde la tienda se manejan todas las peticiones y consultas a la API.
 *   Cada modulo de funcionalidades está separado en la carpeta /modules con sus respectivas mini stores
 * 
 *   Para llamar una accion desde una vista o componente se debe agregar el nombre del modulo que
 *   que corresponde antes del nombre de la accion, seguido de una /
 * 
 *   this.$store.dispatch('nombre_del_modulo/nombre_de_la accion', parametros)
 * 
 *   Para llamar a una variable del state: this.$store.state.nombre_del_modulo.nombre_del_state
 * 
 */

export default new Vuex.Store({
  state: {
    cerrando: false,
    loading: false,
    menu: null,
    menu_nombres: '',
    token: '',
    img_perfil: '',
    sucursales: [],
    locales: [],
    empresas: [],
    banners: [],
    notificaciones: [], // array de notificaciones no leidas
    long_token: '',
    username: '',
    snackbar: {
      value: false,
      text: '',
      color: '',
      timeout: 3000
    }
  },
  mutations: {
    set_token(state, payload) {
      state.token = payload
      state.long_token = 'Basic ' + payload
      localStorage.setItem('token', JSON.stringify(payload))
    },
    set_sucursales(state, payload) {
      state.sucursales = payload
      localStorage.setItem('sucursales', JSON.stringify(payload))
    },
    set_locales(state, payload) {
      state.locales = payload
      localStorage.setItem('locales', JSON.stringify(payload))
    },
    set_empresas(state, payload) {
      state.empresas = payload
      localStorage.setItem('empresas', JSON.stringify(payload))
    },
    set_menu(state, payload) {
      state.menu = payload
      localStorage.setItem('menu', JSON.stringify(payload))
      if (state.menu) {
        let menu_nombres = []
        state.menu.forEach(item => {
          if (item.children) {
            item.children.forEach(child => {
              menu_nombres.push({
                nombre: child.text,
                ruta: child.href,
              })
            })
          } else {
            menu_nombres.push({
              nombre: item.text,
              ruta: item.href,
            })
          }
        })
        state.menu_nombres = menu_nombres 
      }
    },
    set_username(state, payload) {
      state.username = payload
    },
    set_snackbar(state, payload) {
      state.snackbar = payload
    },
    set_dark_theme(state, payload) {
      localStorage.setItem('dark', JSON.stringify(payload))
    },
    set_banners(state, payload) {
      state.banners = payload
      localStorage.setItem('banners', JSON.stringify(payload))
    },
    set_img_perfil(state, payload) {
      state.img_perfil = payload
      localStorage.setItem('img_perfil', JSON.stringify(payload))
    },
    set_notificaciones(state, payload) {
      state.notificaciones = payload
      localStorage.setItem('notificaciones', JSON.stringify(payload))
    },
    delete_notificacion(state, payload) {
      const index = state.notificaciones.indexOf(payload)
      state.notificaciones.splice(index, 1)
      localStorage.setItem('notificaciones', JSON.stringify(state.notificaciones))
    },
    load_token(state, payload) {
      state.token = payload
      state.long_token = 'Basic ' + payload
    }
  },
  actions: {
    // envia todas las notificaciones push pendientes de enviar y no leidas
    async enviar_push ({ state }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/notificaciones/push`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })
          
          resolve({
            exito: 1,
            message: 'Ok'
          })

        } catch (error) {
          reject(error)
        }
      })
    },
    // ----------------------------------
    //            USUARIOS
    // ----------------------------------
    async cambiar_clave ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/${payload.route}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify({
              usuario: Encrypt(payload.user.toUpperCase()),
              clave: Encrypt(payload.password),
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_user_estado ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/estado`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify({
              usuario: payload.toUpperCase(),
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data.estado)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_dark_theme ({ commit, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/user/getDark`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })
        const data = await res.json()

        if (data.exito === 1) {
          commit('set_dark_theme', data.dark)
        }

      } catch (error) {
        console.error(error)
      }
    },
    async set_theme ({ commit, state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/setDark`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify({ dark: payload })
          })

          const data = await res.json()

          if (data.exito === 1) {
            commit('set_dark_theme', payload)
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async menu({ commit, state }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/menuUltron`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })

          const data = await res.json()

          if (data.exito === 1) {
            commit('set_menu', data.result)
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async sincronizar ({ dispatch, commit }) {
      return await new Promise(async (resolve, reject) => {
        await dispatch('menu')
          .then(async () => {
            await dispatch('get_sucursales')
            await dispatch('get_locales')
            await dispatch('get_empresas')
            await dispatch('get_banners')
            await dispatch('get_notificaciones_noleidas')

            // limpia las variables que no se traen en el login
            commit('caja/set_cajas_user', [])
            commit('caja/set_estados_caja', [])
            localStorage.removeItem('areas_sop')

            resolve({
              message: 'Sincronización de perfil exitosa'
            })
          })
          .catch ((error) => {
            reject(error)
          })
      })
    },
    async login ({ commit, dispatch }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              usuario: Encrypt(payload.user.toUpperCase()),
              clave: Encrypt(payload.password),
              subscripcion: localStorage.getItem('subscription')
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            commit('set_token', data.token)
            commit('set_username', Decrypt(jwt.decodeToken(data.token).credenciales.usuario))
            await dispatch('get_sucursales')
            await dispatch('get_locales')
            await dispatch('get_empresas')
            await dispatch('get_dark_theme')
            await dispatch('get_banners')
            await dispatch('get_img_perfil')
            await dispatch('get_notificaciones_noleidas')
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async logout({ commit, state }, redirect) {
      state.cerrando = true
      commit('set_username', '')
      commit('set_token', '')
      commit('set_menu', null)
      commit('set_sucursales', [])
      commit('set_locales', [])
      commit('set_empresas', [])
      commit('set_dark_theme', false)
      commit('set_banners', [])
      commit('set_img_perfil', '')
      commit('set_notificaciones', [])
      // variables de otros modulos
      commit('caja/set_cajas_user', [])
      commit('caja/set_estados_caja', [])
      localStorage.removeItem('areas_sop')
      if (redirect) return location.reload()
      state.cerrando = false
    },

    // ----------------------------------
    //           GENERICOS
    // ----------------------------------
    async get_info_areas_soporte ({ state }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/areasSoporte`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async marcar_notificacion_leida ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/marcarNotificacionLeida`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_notificaciones ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/notificaciones`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + JSON.parse(localStorage.getItem('token'))
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_notificaciones_noleidas ({ dispatch, commit }) {

      await dispatch('get_notificaciones', { estado: 1 })
        .then(res => {
          commit('set_notificaciones', res.data)
        })
        .catch(() => {
          commit('set_notificaciones', [])
        })

    },
    async get_img_perfil ({ dispatch, commit, state }) {

      await dispatch('get_all_files', { path: '/imagenes_perfil/' + state.username, raiz: 1 })
        .then(res => {
          if (res.size > 0) {
            commit('set_img_perfil', res.files[0].file)
          } else {
            commit('set_img_perfil', '')
          }
        })
        .catch(() => {
          commit('set_img_perfil', '')
        })

    },
    async get_all_files ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/getAllFiles`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async delete_file ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/deleteFile`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async upload_file ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/uploadFile`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_banners ({ commit, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/getBanners`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })

        const data = await res.json()

        if (data.exito === 1) {
          commit('set_banners', data.banners)
        } else {
          commit('set_banners', [])
        }

      } catch (error) {
        console.error(error)
      }
    },
    async get_sucursales ({ commit, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/datosUser/sucursales`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })
        const data = await res.json()

        if (data.exito === 1) {
          commit('set_sucursales', data.sucursales)
        } else {
          commit('set_sucursales', [])
        }

      } catch (error) {
        console.error(error)
      }
    },
    async get_locales ({ commit, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/datosUser/locales`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })
        const data = await res.json()

        if (data.exito === 1) {
          commit('set_locales', data.ptos_vta)
        } else {
          commit('set_locales', [])
        }

      } catch (error) {
        console.error(error)
      }
    },
    async get_empresas ({ commit, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/datosUser/empresas`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })
        const data = await res.json()

        if (data.exito === 1) {
          commit('set_empresas', data.empresas)
        } else {
          commit('set_empresas', [])
        }

      } catch (error) {
        console.error(error)
      }
    },
    show_snackbar ({ commit }, payload) {
      let snackbar = {
        value: true,
        text: payload.text,
        color: payload.color,
        timeout: 3000
      }
      if (payload.timeout) {
        snackbar.timeout = payload.timeout
      }
      commit('set_snackbar', snackbar)
    },
    loadLocalStorage({ commit, state }) {
      if (localStorage.getItem('token')) {
        commit('load_token', JSON.parse(localStorage.getItem('token')))
        if (state.token !== '') {
          commit('set_username', Decrypt(jwt.decodeToken(state.token).credenciales.usuario)) 
        }
      } else {
        localStorage.setItem('token', JSON.stringify(state.token))
      }
      commit('set_sucursales', JSON.parse(localStorage.getItem('sucursales')))
      commit('set_locales', JSON.parse(localStorage.getItem('locales')))
      commit('set_empresas', JSON.parse(localStorage.getItem('empresas')))
      commit('set_menu', JSON.parse(localStorage.getItem('menu')))
      commit('set_banners', JSON.parse(localStorage.getItem('banners')))
      commit('set_img_perfil', JSON.parse(localStorage.getItem('img_perfil')))
      commit('set_notificaciones', JSON.parse(localStorage.getItem('notificaciones')))
      // cajas habilitadas del usuario
      if (localStorage.getItem('cajas_user')) commit('caja/set_cajas_user', JSON.parse(localStorage.getItem('cajas_user')))
      else localStorage.setItem('cajas_user', JSON.stringify([]))
      // estados de la caja
      if (localStorage.getItem('est_caja')) commit('caja/set_estados_caja', JSON.parse(localStorage.getItem('est_caja')))
      else localStorage.setItem('est_caja', JSON.stringify([]))
    }
  },
  modules: {
    vendedores,
    caja,
    financiaciones,
    cobros,
    bancos,
    controlCaja,
    laposIntegrado,
    cheques,
    evaluaciones,
    incidentes,
    archivos,
    afip,
    reservas, 
    liquidaciones
  },
  getters: {
    is_logged(state) {
      return !jwt.isExpired(state.token)
    },
    una_empresa (state) {
      return state.empresas.length == 1
    },
    una_sucursal (state) {
      return state.sucursales.length == 1
    }
  }
})